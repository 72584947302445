.Popup__Fade {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Popup {
    background: #fff;
    min-width: 100px;
    min-height: 100px;
    padding: 15px;
    box-shadow: 0 0 14px 10px rgba(53,64,82,.3);
}

.PopupInput {
    width: 80vw;
    margin: 20px 0;
    border: 1px solid rgba(53,64,82,.3);;
    padding: 10px 10px;
    border-radius: 3px;
}