@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&subset=cyrillic,cyrillic-ext,latin-ext');

body {
  font-family: 'Open Sans', 'Helvetica Neue', sans;
  background-color: #f2f2f2;
}
.auth a:link, .auth a:visited, .auth button {
  cursor: pointer;
  color:#1A7DD7;
  background: none;
  border:none;
  padding: 0;
}

.auth span {
  color:#F37402;
}
.auth a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.auth img {
  width: 32px; 
  display: inline-block;
  margin-right: 5px;
}

.auth {
  background-color: rgb(35, 47, 62);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
  color:rgb(238, 238, 238);
  font-size: 1.125rem;
  font-weight: 600;
  padding: 10px 0px 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1001; 
  box-sizing: border-box;
}

.participants-list .dropdown {
  position: absolute;
  min-width: 100px;
  display: block;
  border:1px solid #aaa;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
  background: #ccc;
  z-index: 1000;
  display: none;
}

.participants-list .dropdown ul {
  margin: 0;
  padding:0;
}

.participants-list .dropdown ul li {
 list-style: none;
 padding: 5px 10px;
 cursor: pointer;
 vertical-align: middle;
}

.participants-list .dropdown ul li:hover {
  background: #999;
}
.participants-list .dropdown ul li span {
  padding: 10px;
  margin-right: 10px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.participants-list .list {
  position: relative;
  max-width: 72%;
  margin-left: 20px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
  margin-top: 15px;
  padding: 0;
}

.participants-list {
  padding: 60px 0px 0;
}

.participants-list .list ul {
  width: 100%;
  padding: 0;
  list-style: none;
  padding:0px 0px 0px;
  margin-bottom: 0;;
}

.participants-list .list ul li {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.participants-list .list ul li:hover {
  opacity: 0.8;
}

.participants-list .list ul li.active {
  font-weight: bold;
}
.participants-list .list ul li.header {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.3125rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  margin: 0; 
}

.participants-list .list ul li span.status, .participants-list .list ul li span.comments_count {
  text-align: center;
}

.participants-list .list ul li span {
  flex: 1;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  padding: 14px 40px 14px 16px;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.43;
  overflow: hidden;

}

.participants-list .list ul li span.type {
  flex: 3;
}

.participants-list .list ul li span.name {
  flex: 10;
}

.participants-list .list ul li span.position {
  flex: 10;
}
.participants-list .list ul li span.status {
  flex: 10;
  text-align: left;
}

.participants-list .list ul li span.owner {
  flex: 7;
  text-align: left;
}

.participants-list .list ul li span.table_type {
  flex: 7;
  text-align: left;
}

.participants-list .list ul li span.select {
  flex: 1;
  padding: 14px 20px;
}

.participants-list .list ul li .total {
  flex: 1;
  max-width: 100%;
  margin: 0 auto; 
  display: flex;
  justify-content: space-around;
}

.participants-list .list ul li .total .totalSpan {
  text-align: center;
  font-size: 1.3em;
}

.participants-list .list ul li span.date {
  flex: 5;
}

.participants-list .list ul li span.project {
  flex: 6;
}

.participants-list .list ul li span.comments_count {
  flex: 5;
}

.participants-list .list--filter {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.participants-list .list--filter .PopupInput {
  margin-right: 15px;
  flex: 10;
  padding: 5px 10px;
}

.participants-list .list--filter .StatusSelector {
  flex: 2;
}

.commentBox .commentBoxForm {
  padding: 15px;
  border-bottom:1px solid #8883;
  border-top:1px solid #8883;
  margin-bottom: 0px;
}



@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

.commentBox .commentBoxList {
  margin-top: 10px;
}

.commentBox .commentBoxList .singleComment {
  padding: 5px 15px 0px;
  margin-bottom: 7px;
  border-bottom: 1px solid #CCC;
  transition: opacity .5s ease-in;
  opacity: 0;
  display: none;
}

.commentBox .commentBoxList .singleComment header {
  position: relative;
}


.commentBox .commentBoxList .singleComment .editButton {
  border: 0;
  background: none;
  padding: 0;
}

.commentBox .commentBoxList .singleComment .editButton:hover {
  opacity: 0.4;
  cursor: pointer;

}

.btn {
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: none;
  color: #FFF;
  background-color: #1e88e5;
}

.Comment__TextArea {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  background: #fff;
  min-height: 100px;
  padding: 0px 5px;
  margin-bottom: 5px;;
  width:100%;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}

.Participant_Status {
  position: relative;
}

.Participant_Status::before {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  content: " ";
  display: block;
  border-left: 4px solid var(--statusColor);
}


.Participant_Status__None::before {
  border-left: none;
}

@media only screen and (max-width: 768px) {
  .participants-list .list  {
    margin: 25px 0;
    width: 100%;
    max-width: 100%;
  }

  .participants-list .list ul li  {
    display: block;
  }

  .participants-list .list ul li.header {
    display: none;
  }
  .participants-list .list ul li span {
    display: block;
    padding: 5px 10px;
  }

  .participants-list .list ul li span.status, .participants-list .list ul li span.comments_count {
    text-align: left;
  }
  .participants-list .list ul li span.select {
    display: none;
  }
}


@media print {
  .sidebar {
    display: none;
  }
  .searchForm {
    display: none;
  }
  .auth {
    display: none;
  }
  .listSelector {
    display: none;
  }
  .list {
    min-width: 96%;
    margin-right: 15px;
  }
  .date {
    display: none;
  }

  .owner {
    display: none;
  }
  
  .status {
    display: none;
  }

  .Participant_Status:nth-of-type(odd) {
    background: #eee;
  }

  .participants-list {
    padding: 0;
  }

  .participants-list .list ul li span.select {
    flex: 3;
  }
 } 