@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&subset=cyrillic,cyrillic-ext,latin-ext);
:root {
    --dark-highlite:#23930D;
    --bright-highlite: #D81514;
    --highlite: #333;
    --background: #fff;
    --shadow: #0004;
  }

.w-front {
      width: 40%;
}

.headerH1 small {
    display: block;
}
.signup-form {
    background: #eee;
    background-size: cover;
    min-height: 100vh;
    color:#333;
    color:var(--highlite);
}

.signup-form p {
    color: #333;
    color: var(--highlite);
}

.signup-form p > a:link,
.signup-form p > a:visited {
    color: #D81514;
    color: var(--bright-highlite);
}

.signup-form h1 {
    text-transform: uppercase;
    color: #D81514;
    color: var(--bright-highlite);
    position: relative;
    text-align: center;;
}


.signup-form fieldset input:focus, 
.signup-form fieldset textarea:focus,
.signup-form button:active,
.signup-form button:focus {
    outline: none;
    box-shadow: 0px 2px 20px 2px #0004;
    box-shadow: 0px 2px 20px 2px var(--shadow);
}


.signup-form .WarningBox {
    color: #333;
    color: var(--highlite);
}

.signup-form button:disabled {
    opacity: 0.4;
}

.uploadFiles {
    border: 1px dashed #333;
    border: 1px dashed var(--highlite);
    border-radius: 10px;  
    padding: 20px;
    min-height: 10vh;
    text-align: center;
    margin-bottom: 20px;
}

.uploadFiles ul {
    color: #333;
    color: var(--highlite);
    text-align: left;
    width: 60%;
    margin: 0 auto;
}

.uploadFiles--drag {
    background: #333;
    background: var(--highlite);
    color: #fff;
    color: var(--background);
}
.uploadFiles--drag .uploadFiles__btn {
    border-color: #fff !important;
    border-color: var(--background) !important;
}
.uploadFiles--drag p {
    color: #fff;
    color: var(--background);
}

.uploadFiles__title > strong {
    text-transform: uppercase;;
}

.uploadFiles__btn {
    text-transform: none !important;
}

.uploadFiles input[type='file'] {
    display: none;
}

.signupFormImg {
    max-width: 100%;
}

@-webkit-keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
}

@keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
}

.fileList__loading {
    -webkit-animation: Pulsate 2s linear infinite;
            animation: Pulsate 2s linear infinite;
}

.fileList__loading::after {
    content: " [Loading]";
    font-size: 0.8em;
}

.form-label {
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }
 
 @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }
 
 .WarningBox {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
 }
.PositionDescription {
    height: 400px;
    overflow-y: scroll;
}
.ArtistCard {
    padding: 16px 16px 24px;
}

.participants-list .sidebar {
    background: #fff;
    padding: 0 0 30px;
    position: fixed;
    right: 0px;
    top: 50px;
    height: calc(100vh - 34px);
    overflow-y: scroll;
    box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
  }
  
  .ArtistCard h4 {
    text-transform: uppercase;
    margin-bottom: 10px;
    background: #CCC;
    padding:8px 0px 9px 14px;
  }
  
  .ArtistCard p {
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    padding: 0 0 10px;
    overflow: hidden;
  }
  .ArtistCard__Label {
    display: block;
    font-size: 0.8em;
    color:#777;
  }
  .participants-list .sidebar p {
    text-overflow: ellipsis;
  }
  
  .ArtistCard__Line {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .ArtistCard__File__Link:link, .ArtistCard__File__Link:visited {
      display: block;
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 12px;
      font-size: 0.7em;;
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;;
  }

  .ArtistCard__File__Link:hover {
      background-color: rgba(0, 0, 0, 0.08);
  }

.commentBox header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.commentBox header h5 {
  font-size: 0.8em;
  margin:0;
  justify-content: space-between;
}

.commentBox header small {
  justify-self: flex-end;
  font-size: 0.7em;
  text-align: right;
  flex: 1 1;
}


@media(max-width: 768px) {
  .participants-list .sidebar {
    display: none;;
  }
}
.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
            animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg); 
  }
  .sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg); 
  }
  .sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
  }
  .sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
  }
  .sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
  }
  .sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
  }
  .sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
  }
  .sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
  }
  .sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
  }
  .sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  
  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
  
  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; } 
  }
.Popup__Fade {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Popup {
    background: #fff;
    min-width: 100px;
    min-height: 100px;
    padding: 15px;
    box-shadow: 0 0 14px 10px rgba(53,64,82,.3);
}

.PopupInput {
    width: 80vw;
    margin: 20px 0;
    border: 1px solid rgba(53,64,82,.3);;
    padding: 10px 10px;
    border-radius: 3px;
}
.StatusSelector {
    -webkit-appearance: none;
            appearance: none;
    border: none;
    padding: 5px 10px;
    background-color:  var(--statusColor);
    color: #fff
}

.StatusSelector__None {
    background-color: transparent;
    color: #777;
    border: 1px solid #777;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', sans;
  background-color: #f2f2f2;
}
.auth a:link, .auth a:visited, .auth button {
  cursor: pointer;
  color:#1A7DD7;
  background: none;
  border:none;
  padding: 0;
}

.auth span {
  color:#F37402;
}
.auth a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.auth img {
  width: 32px; 
  display: inline-block;
  margin-right: 5px;
}

.auth {
  background-color: rgb(35, 47, 62);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
  color:rgb(238, 238, 238);
  font-size: 1.125rem;
  font-weight: 600;
  padding: 10px 0px 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 1001; 
  box-sizing: border-box;
}

.participants-list .dropdown {
  position: absolute;
  min-width: 100px;
  display: block;
  border:1px solid #aaa;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
  background: #ccc;
  z-index: 1000;
  display: none;
}

.participants-list .dropdown ul {
  margin: 0;
  padding:0;
}

.participants-list .dropdown ul li {
 list-style: none;
 padding: 5px 10px;
 cursor: pointer;
 vertical-align: middle;
}

.participants-list .dropdown ul li:hover {
  background: #999;
}
.participants-list .dropdown ul li span {
  padding: 10px;
  margin-right: 10px;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.participants-list .list {
  position: relative;
  max-width: 72%;
  margin-left: 20px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
  margin-top: 15px;
  padding: 0;
}

.participants-list {
  padding: 60px 0px 0;
}

.participants-list .list ul {
  width: 100%;
  padding: 0;
  list-style: none;
  padding:0px 0px 0px;
  margin-bottom: 0;;
}

.participants-list .list ul li {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.participants-list .list ul li:hover {
  opacity: 0.8;
}

.participants-list .list ul li.active {
  font-weight: bold;
}
.participants-list .list ul li.header {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.3125rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  margin: 0; 
}

.participants-list .list ul li span.status, .participants-list .list ul li span.comments_count {
  text-align: center;
}

.participants-list .list ul li span {
  flex: 1 1;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px;
  padding: 14px 40px 14px 16px;
  font-size: 0.875rem;
  text-align: left;
  line-height: 1.43;
  overflow: hidden;

}

.participants-list .list ul li span.type {
  flex: 3 1;
}

.participants-list .list ul li span.name {
  flex: 10 1;
}

.participants-list .list ul li span.position {
  flex: 10 1;
}
.participants-list .list ul li span.status {
  flex: 10 1;
  text-align: left;
}

.participants-list .list ul li span.owner {
  flex: 7 1;
  text-align: left;
}

.participants-list .list ul li span.table_type {
  flex: 7 1;
  text-align: left;
}

.participants-list .list ul li span.select {
  flex: 1 1;
  padding: 14px 20px;
}

.participants-list .list ul li .total {
  flex: 1 1;
  max-width: 100%;
  margin: 0 auto; 
  display: flex;
  justify-content: space-around;
}

.participants-list .list ul li .total .totalSpan {
  text-align: center;
  font-size: 1.3em;
}

.participants-list .list ul li span.date {
  flex: 5 1;
}

.participants-list .list ul li span.project {
  flex: 6 1;
}

.participants-list .list ul li span.comments_count {
  flex: 5 1;
}

.participants-list .list--filter {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.participants-list .list--filter .PopupInput {
  margin-right: 15px;
  flex: 10 1;
  padding: 5px 10px;
}

.participants-list .list--filter .StatusSelector {
  flex: 2 1;
}

.commentBox .commentBoxForm {
  padding: 15px;
  border-bottom:1px solid #8883;
  border-top:1px solid #8883;
  margin-bottom: 0px;
}



@-webkit-keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}



@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

.commentBox .commentBoxList {
  margin-top: 10px;
}

.commentBox .commentBoxList .singleComment {
  padding: 5px 15px 0px;
  margin-bottom: 7px;
  border-bottom: 1px solid #CCC;
  transition: opacity .5s ease-in;
  opacity: 0;
  display: none;
}

.commentBox .commentBoxList .singleComment header {
  position: relative;
}


.commentBox .commentBoxList .singleComment .editButton {
  border: 0;
  background: none;
  padding: 0;
}

.commentBox .commentBoxList .singleComment .editButton:hover {
  opacity: 0.4;
  cursor: pointer;

}

.btn {
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: none;
  color: #FFF;
  background-color: #1e88e5;
}

.Comment__TextArea {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  background: #fff;
  min-height: 100px;
  padding: 0px 5px;
  margin-bottom: 5px;;
  width:100%;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}

.Participant_Status {
  position: relative;
}

.Participant_Status::before {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  content: " ";
  display: block;
  border-left: 4px solid var(--statusColor);
}


.Participant_Status__None::before {
  border-left: none;
}

@media only screen and (max-width: 768px) {
  .participants-list .list  {
    margin: 25px 0;
    width: 100%;
    max-width: 100%;
  }

  .participants-list .list ul li  {
    display: block;
  }

  .participants-list .list ul li.header {
    display: none;
  }
  .participants-list .list ul li span {
    display: block;
    padding: 5px 10px;
  }

  .participants-list .list ul li span.status, .participants-list .list ul li span.comments_count {
    text-align: left;
  }
  .participants-list .list ul li span.select {
    display: none;
  }
}


@media print {
  .sidebar {
    display: none;
  }
  .searchForm {
    display: none;
  }
  .auth {
    display: none;
  }
  .listSelector {
    display: none;
  }
  .list {
    min-width: 96%;
    margin-right: 15px;
  }
  .date {
    display: none;
  }

  .owner {
    display: none;
  }
  
  .status {
    display: none;
  }

  .Participant_Status:nth-of-type(odd) {
    background: #eee;
  }

  .participants-list {
    padding: 0;
  }

  .participants-list .list ul li span.select {
    flex: 3 1;
  }
 } 

.Login {
    display: flex;
}

.LoginForm__Title {
    font-size: 1.5em;
    padding-bottom: 15px;
    margin-bottom: 5px;
}
.LoginForm {
    height: 100vh;
}
.LoginForm__Inside {
    box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
    background-color: #fff;
    border-radius: 4px;
}

.LoginForm__A {
    text-decoration: underline;
    border: none;
    background: transparent;
    color: #000;
    padding: 0;
    cursor: pointer;
}


.LoginForm__Field {
    display: flex;
}
.LoginForm__Label {
    flex: 4 1;
}

.LoginForm__Input {
    flex: 10 1;
    padding: 0 5px;
}

.LoginForm__Footer {
    padding-top: 15px;
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.priceSelector {
    width: 100%;
    padding: 5px 10px;
    margin: 20px 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

