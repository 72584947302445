.StatusSelector {
    appearance: none;
    border: none;
    padding: 5px 10px;
    background-color:  var(--statusColor);
    color: #fff
}

.StatusSelector__None {
    background-color: transparent;
    color: #777;
    border: 1px solid #777;
}