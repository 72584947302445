@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }
 
 @keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
 }
 
 .WarningBox {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
 }