
.Login {
    display: flex;
}

.LoginForm__Title {
    font-size: 1.5em;
    padding-bottom: 15px;
    margin-bottom: 5px;
}
.LoginForm {
    height: 100vh;
}
.LoginForm__Inside {
    box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
    background-color: #fff;
    border-radius: 4px;
}

.LoginForm__A {
    text-decoration: underline;
    border: none;
    background: transparent;
    color: #000;
    padding: 0;
    cursor: pointer;
}


.LoginForm__Field {
    display: flex;
}
.LoginForm__Label {
    flex: 4;
}

.LoginForm__Input {
    flex: 10;
    padding: 0 5px;
}

.LoginForm__Footer {
    padding-top: 15px;
    border-top: 1px solid rgba(224, 224, 224, 1);
}