:root {
    --dark-highlite:#23930D;
    --bright-highlite: #D81514;
    --highlite: #333;
    --background: #fff;
    --shadow: #0004;
  }

.w-front {
      width: 40%;
}

.headerH1 small {
    display: block;
}
.signup-form {
    background: #eee;
    background-size: cover;
    min-height: 100vh;
    color:var(--highlite);
}

.signup-form p {
    color: var(--highlite);
}

.signup-form p > a:link,
.signup-form p > a:visited {
    color: var(--bright-highlite);
}

.signup-form h1 {
    text-transform: uppercase;
    color: var(--bright-highlite);
    position: relative;
    text-align: center;;
}


.signup-form fieldset input:focus, 
.signup-form fieldset textarea:focus,
.signup-form button:active,
.signup-form button:focus {
    outline: none;
    box-shadow: 0px 2px 20px 2px var(--shadow);
}


.signup-form .WarningBox {
    color: var(--highlite);
}

.signup-form button:disabled {
    opacity: 0.4;
}

.uploadFiles {
    border: 1px dashed var(--highlite);
    border-radius: 10px;  
    padding: 20px;
    min-height: 10vh;
    text-align: center;
    margin-bottom: 20px;
}

.uploadFiles ul {
    color: var(--highlite);
    text-align: left;
    width: 60%;
    margin: 0 auto;
}

.uploadFiles--drag {
    background: var(--highlite);
    color: var(--background);
}
.uploadFiles--drag .uploadFiles__btn {
    border-color: var(--background) !important;
}
.uploadFiles--drag p {
    color: var(--background);
}

.uploadFiles__title > strong {
    text-transform: uppercase;;
}

.uploadFiles__btn {
    text-transform: none !important;
}

.uploadFiles input[type='file'] {
    display: none;
}

.signupFormImg {
    max-width: 100%;
}

@keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
}

.fileList__loading {
    animation: Pulsate 2s linear infinite;
}

.fileList__loading::after {
    content: " [Loading]";
    font-size: 0.8em;
}

.form-label {
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}