.ArtistCard {
    padding: 16px 16px 24px;
}

.participants-list .sidebar {
    background: #fff;
    padding: 0 0 30px;
    position: fixed;
    right: 0px;
    top: 50px;
    height: calc(100vh - 34px);
    overflow-y: scroll;
    box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
  }
  
  .ArtistCard h4 {
    text-transform: uppercase;
    margin-bottom: 10px;
    background: #CCC;
    padding:8px 0px 9px 14px;
  }
  
  .ArtistCard p {
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    padding: 0 0 10px;
    overflow: hidden;
  }
  .ArtistCard__Label {
    display: block;
    font-size: 0.8em;
    color:#777;
  }
  .participants-list .sidebar p {
    text-overflow: ellipsis;
  }
  
  .ArtistCard__Line {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .ArtistCard__File__Link:link, .ArtistCard__File__Link:visited {
      display: block;
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 12px;
      font-size: 0.7em;;
      margin-bottom: 5px;
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;;
  }

  .ArtistCard__File__Link:hover {
      background-color: rgba(0, 0, 0, 0.08);
  }

.commentBox header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.commentBox header h5 {
  font-size: 0.8em;
  margin:0;
  justify-content: space-between;
}

.commentBox header small {
  justify-self: flex-end;
  font-size: 0.7em;
  text-align: right;
  flex: 1;
}


@media(max-width: 768px) {
  .participants-list .sidebar {
    display: none;;
  }
}